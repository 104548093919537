import axios, { AxiosResponse } from 'axios';
import React from 'react';
import { action, configure, flow, observable } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import moment from 'moment';

import ModalStore from 'components/common/modals/ModalStore';
import TableWrapperStore from 'components/common/wrappers/TableWrapperStore';
//constants
import { ADD, DELETE, GEO_LOCATION, DELETE_REGION, DELETE_COUNTRY } from 'constants/restrictions';
import { AppStore } from '../../AppStore';
//interface
import {
    IRestriction,
    IRegion,
    IDecrypt,
    ICountry,
    ActionType,
    IRegionList,
    IcheckList,
    deleteType,
} from './types';
import getUniqueStr from 'helpers/get-unique-str/getUniqueStr';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

// istanbul ignore next
class GeoLocationStore {
    genericErrorModal = new ModalStore();
    tableWrapper = new TableWrapperStore();
    genericSuccessModal = new ModalStore();
    genericWarningModal = new ModalStore();
    constructor(public rootStore: AppStore | any) {
        this.rootStore = rootStore;
    }
    @observable
    loading = true;
    @observable
    selectedRow = {};
    @observable
    productType = GEO_LOCATION;
    @observable
    targetMemberId = '';
    @observable
    selectedRestriction = '';
    @observable
    checkIds: IcheckList = {};
    @observable
    restrictions: IRestriction[] = [];
    @observable
    ruleName = '';
    @observable
    isRule = false;
    @observable
    rowId = '';
    @observable
    draftRecords: IRestriction[] = [];
    @observable
    isValidForm = false;
    @observable
    restrictionList: IRestriction[] = [];
    @observable
    ruleId = '';
    @observable
    restrictionId = '';
    @observable
    isDraft = false;
    @observable
    allRegions = 'All';
    @observable
    queryRule = '';
    @observable
    isExisting = false;
    @observable
    existingList: IRestriction[] = [];
    @observable
    deleteType: deleteType = DELETE_COUNTRY;
    @observable
    type: ActionType = ADD;
    @observable
    country: ICountry = {
        name: '',
        code: '',
    };
    @observable
    defaultSelectRegion: IRegionList = {
        id: '',
        country: '',
        name: '',
    };
    @observable
    region: IRegionList = {
        id: '',
        name: '',
        country: '',
    };
    @observable
    countries: ICountry[] = [];
    @observable
    regions: IRegionList[] = [];
    @observable
    createdAt = moment().toDate().toISOString();
    @observable
    createdNow = moment().toDate().toISOString();
    @observable
    selectCountry = 'defaultCountry';
    @observable
    selectRegion = 'defaultRegion';
    @observable
    defaultCountry: ICountry = {
        name: '',
        code: '',
    };
    @action
    getRandom = () => {
        return getUniqueStr(8).toUpperCase();
    };
    @action
    setDefaultRegion = (defaultRegion: IRegionList) => {
        this.defaultSelectRegion = defaultRegion;
        this.region = defaultRegion;
    };
    @action
    setAllKeywordValue = (value: string) => {
        this.allRegions = value;
    };

    @action
    setSelectedRegion = (region: IRegionList) => (this.region = region);

    @action
    setCountry = (country: ICountry) => (this.country = country);
    @action
    setDefaultCountry = (country: ICountry) => {
        this.defaultCountry = country;
    };
    @action
    isDefault = () => {
        return this.country.code === this.defaultCountry.code;
    };
    @action
    handleChange = flow(
        function* (this: GeoLocationStore, e: React.ChangeEvent<HTMLInputElement>) {
            const { value, name } = e.target;
            if (name === 'countries') {
                const country = this.countries.find((country: ICountry) => country.name === value);
                if (!country) {
                    this.country = { name: value, code: '' };
                } else {
                    this.setCountry(country);
                    yield this.fetchRegions(country.code);
                    this.setSelectedRegion(this.defaultSelectRegion);
                }
            } else {
                const selectedRegion = this.regions.find((rg) => rg.name === value);
                if (selectedRegion) {
                    this.setSelectedRegion(selectedRegion);
                } else {
                    this.setSelectedRegion(this.defaultSelectRegion);
                }
            }
        }.bind(this),
    );

    @action
    trimText = (text: string) => text.replace(/^[^\w)]+|[^\w)]+$/g, '');
    @action
    checkIfRuleExist = (regionList: IRegion[], newRegion: string) => {
        return regionList.find((region: IRegion) => region.text === newRegion);
    };
    @action
    checkCountry = (countryList: IRestriction[], country: ICountry) => {
        return countryList.find(
            (restriction: IRestriction) => restriction.country === country.name,
        );
    };

    @action
    regionSort = (data: IRegionList[]) => {
        const result = data.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        return result;
    };
    @action
    countrySort = (data: ICountry[]) => {
        const result = data.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
        return result;
    };
    @action
    fetchCountriesList = flow(
        function* (
            this: GeoLocationStore,
        ): Generator<
            Promise<AxiosResponse<{ countries: ICountry[] }>>,
            void,
            AxiosResponse<{ countries: ICountry[] }>
        > {
            try {
                const resp = yield axios.get('/api/member/countries');
                const countryList = resp.data.countries.map(({ name, code }: ICountry) => ({
                    code,
                    name: this.trimText(name),
                }));
                this.countries = this.countrySort(countryList);
            } catch (error) {
                this.rootStore.GenericErrorStore.openErrorModal(error);
            }
        }.bind(this),
    );
    @action
    fetchRegions = flow(
        function* (
            this: GeoLocationStore,
            countryCode: string,
        ): Generator<
            Promise<AxiosResponse<{ regions: IRegionList[] }>>,
            void,
            AxiosResponse<{ regions: IRegionList[] }>
        > {
            try {
                const resp = yield axios.get(`/api/member/regions/${countryCode}`);
                const regionList = resp.data.regions.map((item) => ({
                    ...item,
                    name: this.trimText(item.name),
                }));
                this.regions = this.regionSort(regionList);
                this.checkIds = {};
            } catch (error) {
                console.error(error);
                this.rootStore.GenericErrorStore.openErrorModal(error);
            }
        }.bind(this),
    );
    @action
    setDraftRecord = (record: IRestriction) => {
        this.draftRecords = this.draftRecords.filter(
            ({ countryCode }: IRestriction) => countryCode !== record?.countryCode,
        );
        this.draftRecords.push(record);
    };
    @action
    modifyRestrictionList = (restrictions: IRestriction[], country: ICountry) => {
        this.createdAt = moment(this.createdAt).toISOString();
        this.createdNow = moment(this.createdNow).toLocaleString();
        let restrictionRecord = this.checkCountry(restrictions, country);
        if (restrictionRecord) {
            if (this.checkIfRuleExist(restrictionRecord.childrenList, this.region.name)) {
                return false;
            } else {
                const rule: IRegion = {
                    text: this.region.name,
                    isDraft: true,
                    ruleId: restrictionRecord.ruleId,
                    id: restrictionRecord.id,
                    name: `GeoLocation${this.getRandom()}`,
                    enabled: true,
                    restrictionId: this.restrictionId,
                    childrenList: [],
                };
                restrictionRecord = {
                    ...restrictionRecord,
                    isDraft: true,
                    createdAt: this.createdAt,
                    text: restrictionRecord.childrenList.length + 1,
                    childrenList: [{ ...rule }, ...restrictionRecord?.childrenList],
                };
                const restrictionIndex = this.restrictions.findIndex(
                    (item: IRestriction) => item.country === country.name,
                );
                this.restrictions[restrictionIndex] = restrictionRecord;
                this.setDraftRecord(restrictionRecord);
                this.tableWrapper.setData(this.restrictions);
                return true;
            }
        } else {
            const ruleId = `rule-${Math.random()}`;
            const rule: IRegion = {
                text: this.region.name,
                id: this.restrictionId,
                isDraft: true,
                ruleId,
                name: `GeoLocation${this.getRandom()}`,
                enabled: true,
                restrictionId: this.restrictionId,
                childrenList: [],
            };
            restrictionRecord = {
                productType: GEO_LOCATION,
                enabled: true,
                isDraft: true,
                id: this.restrictionId,
                targetMemberId: this.rootStore.memberId,
                restrictionId: this.restrictionId,
                ruleId,
                text: 1,
                childrenList: [{ ...rule }],
                createdAt: this.createdNow,
                country: this.country.name,
                countryCode: this.country['code'],
            };
            this.restrictions = [restrictionRecord, ...this.restrictions];
            this.setDraftRecord(restrictionRecord);
            this.tableWrapper.setData(this.restrictions);
            return true;
        }
    };
    @action
    addNewRestriction = (restrictions: IRestriction[], country: ICountry) => {
        let restrictionRecord = this.checkCountry(restrictions, country);
        if (!restrictionRecord) {
            restrictionRecord = {
                productType: GEO_LOCATION,
                enabled: true,
                isDraft: true,
                id: this.restrictionId,
                restrictionId: this.restrictionId,
                ruleId: `rule-${Math.random()}`,
                targetMemberId: this.rootStore.memberId,
                text: this.allRegions,
                childrenList: [],
                createdAt: this.createdNow,
                country: this.country.name,
                countryCode: this.country['code'],
            };
            this.setDraftRecord(restrictionRecord);
            this.restrictions = [restrictionRecord, ...this.restrictions];
            this.tableWrapper.setData(this.restrictions);
            return true;
        }
        return false;
    };
    @action
    handleModifyRow = flow(
        function* (this: GeoLocationStore, memberId: string, row: IRegion, selectedRegion: string) {
            this.loading = true;
            try {
                const selectedRestriction = this.restrictions.find((restriction: IRestriction) =>
                    restriction.childrenList.find(
                        (region: IRegion) => region.text === selectedRegion,
                    ),
                );
                if (selectedRestriction) {
                    const nameList: string[] = selectedRestriction.childrenList
                        .map((region) => region.text)
                        .filter((rgn: string) => rgn !== selectedRegion);
                    let regionQuery = '';
                    if (nameList.length > 1) {
                        regionQuery = '&& (';
                        let appendQuery = '';
                        nameList.map((name: string, index) => {
                            if (index === nameList.length - 1) {
                                appendQuery += `region == "${name}")`;
                            } else {
                                appendQuery += `region == "${name}" || `;
                            }
                        });
                        regionQuery += appendQuery;
                    }
                    if (nameList.length === 1) {
                        regionQuery = `&& region == "${nameList[0]}"`;
                    }
                    const body = {
                        memberId: memberId,
                        restrictionId: row.restrictionId,
                        ruleId: row.id,
                        updatedRule: {
                            enable: row.enabled,
                            id: row.id,
                            text: `GeoLocationRestrictedFields(country == "${selectedRestriction.countryCode}" ${regionQuery})`,
                            name: row.name,
                            updatedAt: moment(Date.now()).toDate().getTime(),
                            createdAt: row.createdAt,
                        },
                    };
                    yield axios.put('/api/member/restriction-rule', body);
                    this.handleFetch();
                }
            } catch (error) {
                this.rootStore.GenericErrorStore.openErrorModal(error);
                console.error(error);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );
    @action
    formatQuery = () => {
        const rules: IRegion[] = [];
        this.restrictions.map((record: IRestriction, index) => {
            const countryCode = record.countryCode;
            let regionQuery = '';
            if (record.childrenList.length > 1) {
                regionQuery = '&& (';
                let appendQuery = '';
                record.childrenList.map((r: IRegion, idx) => {
                    if (idx === record.childrenList.length - 1) {
                        appendQuery += `region == "${r.text}")`;
                    } else {
                        appendQuery += `region == "${r.text}" || `;
                    }
                });
                regionQuery += appendQuery;
            }
            if (record.childrenList.length === 1) {
                regionQuery = `&& region == "${record.childrenList[0].text}"`;
            }

            rules[index] = {
                id: record.ruleId,
                ruleId: record.ruleId,
                name: record.childrenList?.[0]?.name || `GeoLocation${this.getRandom()}`,
                enabled: record.enabled,
                restrictionId: record.restrictionId,
                createdAt: moment(record.createdAt).toISOString(),
                text: `GeoLocationRestrictedFields(country == "${countryCode}" ${regionQuery})`,
            };
        });

        return rules;
    };
    @action
    handleModifyRestriction = flow(
        function* (this: GeoLocationStore) {
            this.createdAt = moment(this.createdAt).toISOString();
            this.loading = true;
            try {
                if (this.isExisting) {
                    const body = {
                        restrictionId: this.draftRecords[0].id,
                        updatedRestriction: {
                            id: this.draftRecords[0].id,
                            productType: this.productType,
                            enabled: this.draftRecords[0].enabled,
                            targetMemberId: this.rootStore.memberId,
                            createdAt: this.createdAt,
                            updatedAt: moment(Date.now()).toDate().getTime(),
                            ownerMemberId: this.draftRecords[0].ownerMemberId,
                            rules: this.formatQuery(),
                        },
                    };
                    yield axios.put('/api/member/payment-initiation-restrictions', body);
                } else {
                    const body = {
                        targetMemberId: this.rootStore.memberId,
                        restrictionPayload: {
                            productType: this.productType,
                            rulePayloads: this.formatQuery(),
                        },
                    };
                    yield axios.post('/api/member/payment-initiation-restrictions', body);
                }
                this.draftRecords = [];
                yield this.handleFetch();
                this.isDraft = false;
                this.checkIds = {};
                this.genericSuccessModal.openModal();
            } catch (error) {
                this.rootStore.GenericErrorStore.openErrorModal(error);
                console.error(error);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );
    @action
    handleSave = flow(
        function* (this: GeoLocationStore) {
            try {
                yield this.handleModifyRestriction();
            } catch (error) {
                console.error(error);
            }
        }.bind(this),
    );
    @action
    addRestriction = () => {
        this.isExisting = this.existingList.length >= 1;
        if (this.region.name !== this.defaultSelectRegion.name && this.region.name !== '') {
            this.isDraft = this.modifyRestrictionList(this.restrictions, this.country);
        } else {
            this.isDraft = this.addNewRestriction(this.restrictions, this.country);
        }
        if (this.isDraft) {
            this.type = ADD;
        }
    };
    @action
    formatRuleList = (ruleList: IRegion[], id: string) => {
        ruleList.map((rule: IRegion, index) => {
            ruleList[index] = {
                ...rule,
                restrictionId: id,
                childrenList: [],
            };
        });
        return ruleList;
    };
    @action
    fetchKeyValue = (text: string) => {
        const [key, value] = text.split('==');
        return { key, value };
    };
    @action
    decryptRule = (ruleText: string): IDecrypt => {
        if (ruleText.includes('||')) {
            const regionArr: string[] = [];
            const [country, regions] = ruleText.split('&&');
            const countryObj = this.fetchKeyValue(country);
            const stripText = regions.replace(/[()]/g, '');
            const stripTextArr = stripText.split('||');
            stripTextArr.map((str: string) => {
                const { value } = this.fetchKeyValue(str);
                regionArr.push(value);
            });
            return { countryCode: countryObj.value, region: regionArr };
        }
        if (ruleText.includes('&&')) {
            const [country, region] = ruleText.split('&&');
            const countryObj = this.fetchKeyValue(country);
            const regionObj = this.fetchKeyValue(region);
            return { countryCode: countryObj.value, region: [regionObj.value] };
        }
        const { value } = this.fetchKeyValue(ruleText);
        return { countryCode: value, region: [] };
    };

    @action
    formatRestrictionList = (restrictionList: IRestriction[]) => {
        const restrictByCountryCodeList: IRestriction[] = [];
        restrictionList.forEach((restriction: IRestriction) => {
            this.restrictionId = restriction.id;
            let regions: IRegion[] = [];
            if (restriction.rules) {
                restriction.rules.map((item: IRegion, i) => {
                    let data = item.text.replace('GeoLocationRestrictedFields(', '');
                    const lastIndex = data.lastIndexOf(')');
                    if (lastIndex !== -1) {
                        data = data.substring(0, lastIndex);
                    }
                    const decryptedRule = this.decryptRule(data);
                    regions = decryptedRule.region.map((rgn: string) => ({
                        ...item,
                        text: this.trimText(rgn),
                    }));
                    const code = this.trimText(decryptedRule.countryCode);
                    const country = this.countries.find(
                        (country: ICountry) => country.code === code,
                    );
                    if (country) {
                        restrictByCountryCodeList.push({
                            ...restriction,
                            countryCode: country.code,
                            restrictionId: restriction.id,
                            ruleId: item.id,
                            text:
                                decryptedRule.region.length > 0
                                    ? decryptedRule.region.length
                                    : this.allRegions,
                            country: country.name,
                            childrenList: this.formatRuleList(regions, restriction.id),
                        });
                        delete restrictByCountryCodeList[i].rules;
                    }
                });
            }
        });

        return restrictByCountryCodeList;
    };
    @action
    updateSelectRow = (row: IRegion | IRestriction) => {
        this.selectedRow = row;
        if ((this.selectedRow as IRegion).text) {
            this.setValues(
                row.restrictionId,
                true,
                (this.selectedRow as IRegion).text,
                (this.selectedRow as IRegion).name,
                row.ruleId,
            );
        } else {
            this.setValues(
                row.restrictionId,
                false,
                (this.selectedRow as IRestriction)?.childrenList?.[0]?.text,
                (this.selectedRow as IRestriction)?.childrenList?.[0]?.name,
                row.id,
            );
        }
    };
    @action
    handleDelete = flow(
        function* (this: GeoLocationStore | any) {
            this.loading = true;
            try {
                const memberId = this.rootStore.memberId;
                if (this.deleteType === DELETE_COUNTRY) {
                    //Remove the only restriction if rule is deleted
                    if (this.restrictions.length === 1) {
                        yield axios.delete(
                            `/api/member/payment-initiation-restrictions?memberId=${memberId}&restrictionId=${this.restrictionId}`,
                        );
                    } else {
                        yield axios.delete(
                            `/api/member/restriction-rule?memberId=${memberId}&ruleId=${this.ruleId}&restrictionId=${this.restrictionId}`,
                        );
                    }
                } else {
                    const selectedRegion = Object.keys(this.checkIds)[0];
                    this.handleModifyRow(memberId, this.selectedRow, selectedRegion);
                }
                this.type = DELETE;
                this.genericWarningModal.closeModal();
                this.genericSuccessModal.openModal();
                yield this.handleFetch();
                this.checkIds = {};
            } catch (e) {
                console.error(e);
                this.genericErrorModal.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );
    @action
    handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = e.target;
        this.checkIds = {};
        const checkDraft = this.draftRecords.findIndex(
            (res) =>
                res.country === name ||
                res.childrenList.findIndex(({ text }) => text === name) !== -1,
        );
        if (checkDraft !== -1) {
            const key = name as keyof IcheckList;
            this.checkIds[key] = checked;
            this.loading = true;
            const removeFromDraft: IRestriction[] = [];
            this.restrictions.map((res) => {
                const IsDraftrestriction =
                    res.country === name || res.childrenList.find((child) => child.text === name);
                const childrenList = res.childrenList.filter(({ text }) => text !== name);
                if (res.country !== name) {
                    removeFromDraft.push({
                        ...res,
                        text:
                            childrenList.length === 0
                                ? this.allRegions
                                : IsDraftrestriction
                                ? (res.text as number) - 1
                                : res.text,
                        childrenList,
                    });
                }
            });
            this.restrictions = removeFromDraft;
            this.tableWrapper.setData(this.restrictions);
            this.loading = false;
        } else {
            const renderModal = () => checked && this.genericWarningModal.openModal();
            const key = name as keyof IcheckList;
            const restrictionIndex = this.restrictions.findIndex((item) => item.country === name);
            if (restrictionIndex !== -1) {
                this.checkIds[key] = checked;
                this.deleteType = DELETE_COUNTRY;
                const { childrenList } = this.restrictions[restrictionIndex];
                if (childrenList.length) {
                    childrenList.map(({ text }) => {
                        this.checkIds[text] = checked;
                    });
                }
                renderModal();
            } else {
                this.deleteType = DELETE_REGION;
                this.checkIds[name] = checked;
                renderModal();
            }
            this.restrictionList.find((r) => {
                if (r.country === name && r.childrenList.length > 0) {
                    r.childrenList.forEach(({ text }) => {
                        this.checkIds[text] = checked;
                    });
                }
            });
        }
    };
    @action
    setValues = (
        restrictionId: string,
        isRule: boolean,
        ruleText: string,
        ruleName: string,
        ruleId = '',
    ) => {
        if (restrictionId) {
            this.restrictionId = restrictionId;
            this.isRule = isRule;
            this.queryRule = ruleText;
            this.ruleName = ruleName;
            this.ruleId = ruleId;
        }
    };
    @action
    handleFetch = flow(
        function* (
            this: GeoLocationStore,
        ): Generator<
            Promise<AxiosResponse<{ restrictions: IRestriction[] }>>,
            void,
            AxiosResponse<{ restrictions: IRestriction[] }>
        > {
            this.loading = true;
            this.selectedRestriction = '';
            try {
                const byTargetMemberId = this.rootStore.member.memberId;
                const byRestrictionId = this.restrictionId;
                // API to fetch all restrictions for a member
                const res = yield axios.get(
                    `/api/member/payment-initiation-restrictions?byTargetMemberId=${byTargetMemberId}&byRestrictionId=${byRestrictionId}`,
                );
                this.restrictions = res.data.restrictions;
                this.restrictions = this.restrictions.filter(
                    (restriction: IRestriction) => restriction.productType === GEO_LOCATION,
                );
                this.restrictions = this.formatRestrictionList(this.restrictions);
                this.existingList = this.restrictions;
                this.tableWrapper.setData(this.restrictions);
            } catch (e) {
                console.error(e);
                this.genericErrorModal.openErrorModal(e);
            } finally {
                this.loading = false;
            }
        }.bind(this),
    );
}

export default GeoLocationStore;
