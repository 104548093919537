import axios from 'axios';
import { action, computed, configure, flow, observable, toJS } from 'mobx';
import { useStaticRendering } from 'mobx-react';
import moment from 'moment';

import ColorPickerStore from '../css/color-picker/ColorPickerStore';
import ModalStore from 'components/common/modals/ModalStore';

// constants
import { RESELLER } from 'constants/clientType';
import { getDefaultCss } from 'constants/defaultCss';
import { getLegalTextMessages } from 'constants/legalTextMessages';
import {
    CUSTOM,
    LEGAL_TEXT_TYPES,
    NO_FOOTER_LOGO,
    TRUST_LOGOS,
    footerType,
} from 'constants/webAppV2';

// helpers
import asyncReadFile from 'helpers/async-read-file/asyncReadFile';
import _isEmpty from 'lodash/isEmpty';
import _merge from 'lodash/merge';

// types
import { AsyncGeneratorReturnType, ITPPFeatureControl } from 'types';
import type ConfigurationStore from 'components/configuration/ConfigurationStore';
import IConfigurations, { LegalTextTypes, LogoType, SupportedLanguages } from './types';
import asyncImageLoad from 'helpers/async-image-load/asyncImageLoad';
import getUniqueStr from 'helpers/get-unique-str/getUniqueStr';

const isServer = typeof window === 'undefined';
useStaticRendering(isServer);
configure({
    enforceActions: 'observed',
});

const { publicRuntimeConfig } = require('next/config').default();

class WebAppV2Store {
    constructor(public rootStore: ConfigurationStore) {
        this.rootStore = rootStore;
    }
    GenericSuccessStore = new ModalStore();
    GenericErrorStore = new ModalStore();
    displayName = '';
    helpTextFields = [
        'enableTransactionStatusPage',
        'showDefaultQrCode',
        'enableOnboardingScreen',
        'enableFeedbackSurvey',
    ];
    defaultSessionDetailsSettings: {
        [key: string]: boolean;
    } = {
        showRemittanceInformationSecondary: true,
        showCreditorAccountName: false,
        showCreditorAccountIdentifier: false,
        showCreditorBankIdentifier: false,
        showDebtorAccountIdentifier: false,
        showDebtorBankIdentifier: false,
    };
    defaultFlowCustomizations: {
        [key: string]: boolean;
    } = {
        enableOnboardingScreen: true,
        enableFeedbackSurvey: false,
        enableTransactionStatusPage: false,
        enableAutoMode: true,
        showDefaultQrCode: false,
    };
    defaultLegalTexts = getLegalTextMessages;
    defaultLegalForm = {
        displayName: '',
        businessName: 'Token',
        expirationDate: moment(new Date()).add(90, 'day').format('MMMM Do YYYY'),
        ...this.defaultLegalTexts,
    };
    defaultColorOptions: {
        [key: string]: {
            light: {
                value: string;
                colorPickerStore: ColorPickerStore;
            };
            dark: {
                value: string;
                colorPickerStore: ColorPickerStore;
            };
        };
    } = {
        accentColor: {
            light: {
                value: getDefaultCss['color-light-accent'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-accent'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        secondaryAccentColor: {
            light: {
                value: getDefaultCss['color-light-secondary-accent'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-secondary-accent'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        successColor: {
            light: {
                value: getDefaultCss['color-light-status-success'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-status-success'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        submittedColor: {
            light: {
                value: getDefaultCss['color-light-status-submitted'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-status-submitted'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        failureColor: {
            light: {
                value: getDefaultCss['color-light-status-failure'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-status-failure'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
        explicitConsentColor: {
            light: {
                value: getDefaultCss['color-light-explicit-consent'],
                colorPickerStore: new ColorPickerStore(),
            },
            dark: {
                value: getDefaultCss['color-dark-explicit-consent'],
                colorPickerStore: new ColorPickerStore(),
            },
        },
    };
    emptyLogo = {
        data: '',
        mimeType: '',
    };

    @observable
    modalTabView = 0;
    @observable
    previewWAV2 = false;
    @observable
    enableDemoId = false;
    @observable
    demoId = '';
    @observable
    loadingSaveButton = false;
    @observable
    language: SupportedLanguages = 'en';
    @observable
    legalForm = this.defaultLegalForm;
    @observable
    flowCustomisationList = { ...this.defaultFlowCustomizations };
    @observable
    sessionDetailsSettingsList = { ...this.defaultSessionDetailsSettings };
    @observable
    colorOptions: {
        [key: string]: {
            light: {
                value: string;
                colorPickerStore: ColorPickerStore;
            };
            dark: {
                value: string;
                colorPickerStore: ColorPickerStore;
            };
        };
    } = { ...this.defaultColorOptions };
    @observable
    hasAnyLogoChanged = false;
    @observable
    tppLogoLight = this.emptyLogo;
    @observable
    tppLogoDark = this.emptyLogo;
    @observable
    faviconLight = this.emptyLogo;
    @observable
    faviconDark = this.emptyLogo;
    @observable
    footerLogoLight = this.emptyLogo;
    @observable
    footerLogoDark = this.emptyLogo;
    @observable
    footerType: footerType = TRUST_LOGOS;
    @observable
    showLightTppLogoError: boolean = false;
    @observable
    showDarkTppLogoError: boolean = false;
    @observable
    showLightFaviconError: boolean = false;
    @observable
    showDarkFaviconError: boolean = false;
    @observable
    showLightFooterLogoError: boolean = false;
    @observable
    showDarkFooterLogoError: boolean = false;
    @observable
    tosContent: { [key: string]: string } = {};
    @observable
    ppContent: { [key: string]: string } = {};
    @observable
    disableSaveButton: boolean = false;
    @observable
    updatedConfigurations: IConfigurations = {};
    @observable
    fetchedConfigurations: IConfigurations = {};
    @observable
    customLogosNotUploaded: boolean = false;
    @observable
    isLegalTextEditable = true;
    wav2Modal: any;
    @observable
    realmId = '';

    @action
    handleModalTabChange = (_: any, newValue: number) => {
        this.modalTabView = newValue;

        const modalWidth = newValue === 1 ? '420px' : '700px';

        const messenger = new window.TokenMessenger({
            source: window,
            destination: window,
        });

        messenger?.dispatchMessage({
            type: 'SET_MODAL_STYLE',
            data: { width: modalWidth },
        });
    };

    @action
    setLanguage = (lang: SupportedLanguages) => {
        this.language = lang;
    };

    @computed
    get enableSave(): boolean {
        return (
            !this.disableSaveButton &&
            (Object.keys(toJS(this.updatedConfigurations)).length != 0 || this.hasAnyLogoChanged)
        );
    }

    @action
    restoreLegalTextToDefault = (type: LegalTextTypes) => {
        this.handleTranslationChange(type, this.defaultLegalTexts[type][this.language]);
    };

    @action
    setEnableDemoId = (enable: boolean) => {
        this.enableDemoId = enable;
        if (this.enableDemoId) {
            // TODO: Fetch demoId from the server
            this.demoId = 'demo-id-001';
        }
    };

    @action
    copyDemoId = async () => {
        try {
            await navigator.clipboard.writeText(this.demoId);
        } catch (err) {
            console.error('Failed to copy Demo ID:', err);
        }
    };

    @action
    handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        this.legalForm[e.target.name as 'businessName'] = value;
        if (this.fetchedConfigurations.businessName !== value) {
            this.updatedConfigurations.businessName = value;
        } else {
            delete this.updatedConfigurations?.businessName;
        }
    };

    @action
    populateLegalTextsLoaders = () => {
        this.legalForm = this.defaultLegalForm;
    };

    @action
    setFooterType = (footerType: footerType) => {
        this.footerType = footerType;
        this.customLogosNotUploaded = false;

        this.updatedConfigurations.enableTrustIcons = {
            value: this.footerType === TRUST_LOGOS,
        };
    };

    @action
    setLogo = (type: string, data: string, logoName: LogoType) => {
        this[logoName] = {
            data: `data:${type};base64,${data}`,
            mimeType: type,
        };
    };

    @action
    setLogos = async () => {
        const { darkMode, lightMode } = this.fetchedConfigurations;
        const logos: Record<string, any> = {};
        const fileReferences = {
            tppLogoDark: darkMode?.logoReference ?? '',
            tppLogoLight: lightMode?.logoReference ?? '',
            footerLogoLight: lightMode?.footerLogoReference ?? '',
            footerLogoDark: darkMode?.footerLogoReference ?? '',
            faviconLight: lightMode?.faviconReference ?? '',
            faviconDark: darkMode?.faviconReference ?? '',
        };

        const logoPromises = Object.keys(fileReferences).map(async (reference) => {
            if (fileReferences[reference as LogoType]) {
                logos[reference] = await this.getLogo(fileReferences[reference as LogoType]);
            }
        });
        await Promise.all(logoPromises);
        Object.keys(logos).map((logo) => {
            if (logos[logo]?.data) {
                const { data, type } = logos[logo];
                this.setLogo(type, data, logo as LogoType);
            }
        });
    };

    @computed
    get isTppLogoLightUploaded() {
        return !!this.tppLogoLight.data;
    }

    @computed
    get isTppLogoDarkUploaded() {
        return !!this.tppLogoDark.data;
    }

    @computed
    get isFaviconLightUploaded() {
        return !!this.faviconLight.data;
    }

    @computed
    get isFaviconDarkUploaded() {
        return !!this.faviconDark.data;
    }

    @computed
    get isFooterLogoLightUploaded() {
        return !!this.footerLogoLight.data;
    }

    @computed
    get isFooterLogoDarkUploaded() {
        return !!this.footerLogoDark.data;
    }

    @action
    setDefaultConfigurationsOfReseller = flow(
        function* (): AsyncGeneratorReturnType {
            const { memberId, refId: subTppId } = this.rootStore;
            const configMemberId = subTppId ? memberId : this.realmId;
            yield this.fetchDisplayName(configMemberId);

            const res = yield this.fetchConfigurations(configMemberId);
            const {
                tppConfiguration: { configuration },
            } = res.data;
            this.fetchedConfigurations = configuration;

            this.language = 'en';
            this.enableDemoId = false;
            // Update `updatedConfigurations` for default flow customizations
            Object.keys(this.defaultFlowCustomizations).forEach((key) => {
                if (typeof configuration?.[key]?.value === 'boolean') {
                    this.updatedConfigurations[key] = {
                        value: configuration[key]?.value,
                    };
                    this.flowCustomisationList[key] = configuration[key]?.value;
                } else {
                    this.updatedConfigurations[key] = null;
                    this.flowCustomisationList[key] = this.defaultFlowCustomizations[key];
                }
            });

            // Update `updatedConfigurations` for default session details settings
            Object.keys(this.defaultSessionDetailsSettings).forEach((key) => {
                if (typeof configuration?.[key]?.value === 'boolean') {
                    this.updatedConfigurations[key] = {
                        value: configuration[key]?.value,
                    };
                    this.sessionDetailsSettingsList[key] = configuration[key]?.value;
                } else {
                    this.updatedConfigurations[key] = null;
                    this.sessionDetailsSettingsList[key] = this.defaultSessionDetailsSettings[key];
                }
            });

            // Handle legal text translations
            LEGAL_TEXT_TYPES.forEach((type) => {
                const fetchedLegalTranslations = configuration[type];
                if (!_isEmpty(fetchedLegalTranslations)) {
                    Object.entries(fetchedLegalTranslations).forEach(([key, value]) => {
                        this.updatedConfigurations[type] = {
                            ...this.updatedConfigurations[type],
                            [key]: value,
                        };
                    });
                } else {
                    this.updatedConfigurations[type] = {};
                }
            });

            this.legalForm = LEGAL_TEXT_TYPES.reduce((acc, type) => {
                acc[type] = {
                    ...this.defaultLegalForm[type],
                    ...this.fetchedConfigurations?.[type],
                };
                return acc;
            }, {} as typeof this.defaultLegalForm);

            // Set business name
            this.updatedConfigurations['businessName'] = configuration.businessName;
            this.updatedConfigurations['enableTrustIcons'] = configuration.enableTrustIcons;
            this.legalForm.displayName = this.displayName;
            this.legalForm.businessName = configuration.businessName;
            const { lightMode, darkMode, enableTrustIcons } = configuration;
            if (lightMode?.footerLogoReference || darkMode?.footerLogoReference) {
                this.footerType = CUSTOM;
            } else if (enableTrustIcons?.value === false) {
                this.footerType = NO_FOOTER_LOGO;
            } else {
                this.footerType = TRUST_LOGOS;
            }

            // Handle terms and conditions and privacy policy
            ['termsAndConditions', 'privacyPolicy'].forEach((type) => {
                const legalConfig = configuration[type] || {};
                if (!_isEmpty(legalConfig)) {
                    Object.keys(legalConfig).forEach((key) => {
                        this.updatedConfigurations[type] = {
                            ...this.updatedConfigurations[type],
                            [key]: legalConfig[key],
                        };
                    });
                } else {
                    this.updatedConfigurations[type] = {};
                }
            });
            this.tosContent = configuration?.termsAndConditions;
            this.ppContent = configuration?.privacyPolicy;

            // Handle light and dark mode
            ['lightMode', 'darkMode'].forEach((themeType) => {
                const themeConfig = configuration[themeType] || {};
                if (!_isEmpty(themeConfig)) {
                    Object.entries(themeConfig).forEach(([key, value]) => {
                        if (value) {
                            this.updatedConfigurations[themeType] = {
                                ...this.updatedConfigurations[themeType],
                                [key]: value,
                            };
                        }
                    });
                }
            });

            for (const key in this.colorOptions) {
                this.colorOptions[key].light.value =
                    this.fetchedConfigurations?.lightMode?.[key] ??
                    this.defaultColorOptions[key].light.value;

                this.colorOptions[key].dark.value =
                    this.fetchedConfigurations?.darkMode?.[key] ??
                    this.defaultColorOptions[key].dark.value;
            }

            this.updatedConfigurations.lightMode = {
                ...this.updatedConfigurations['lightMode'],
                logoReference: configuration.lightMode?.logoReference ?? null,
            };
            this.updatedConfigurations.darkMode = {
                ...this.updatedConfigurations['darkMode'],
                logoReference: configuration.darkMode?.logoReference ?? null,
            };

            this.tppLogoLight = this.fetchedConfigurations?.lightMode?.logoReference
                ? { ...this.fetchedConfigurations.lightMode.logoReference }
                : this.emptyLogo;

            this.tppLogoDark = this.fetchedConfigurations?.darkMode?.logoReference
                ? { ...this.fetchedConfigurations.darkMode.logoReference }
                : this.emptyLogo;

            this.footerLogoLight = this.fetchedConfigurations?.lightMode?.footerLogoReference
                ? { ...this.fetchedConfigurations.lightMode.footerLogoReference }
                : this.emptyLogo;

            this.footerLogoDark = this.fetchedConfigurations?.darkMode?.footerLogoReference
                ? { ...this.fetchedConfigurations.darkMode.footerLogoReference }
                : this.emptyLogo;

            yield this.setLogos();
        }.bind(this),
    );

    @action
    setDefaultConfigurations = () => {
        const defaultSessionAndFlowSettings: {
            [key: string]: boolean;
        } = {
            ...this.defaultSessionDetailsSettings,
            ...this.defaultFlowCustomizations,
            enableTrustIcons: true,
        };
        Object.keys(defaultSessionAndFlowSettings).forEach((key) => {
            if (typeof this.fetchedConfigurations[key]?.value === 'boolean') {
                this.updatedConfigurations[key] = {
                    value: defaultSessionAndFlowSettings[key],
                };
            }
        });
        LEGAL_TEXT_TYPES.forEach((type) => {
            const fetchedLegalTranslations = this.fetchedConfigurations[type];
            if (!_isEmpty(fetchedLegalTranslations)) {
                const legalTranslations = this.legalForm[type];
                Object.keys(legalTranslations).forEach((key) => {
                    if (key in fetchedLegalTranslations) {
                        this.updatedConfigurations[type] = {
                            ...this.updatedConfigurations[type],
                            [key]: legalTranslations[key as SupportedLanguages],
                        };
                    }
                });
            }
        });
        this.updatedConfigurations['businessName'] = 'Token';
        ['termsAndConditions', 'privacyPolicy'].forEach((type) => {
            if (!_isEmpty(this.fetchedConfigurations[type])) {
                Object.keys(this.fetchedConfigurations[type]).forEach((key) => {
                    this.updatedConfigurations[type] = {
                        ...this.updatedConfigurations[type],
                        [key]: '',
                    };
                });
            }
        });
        ['lightMode', 'darkMode'].forEach((type) => {
            const theme = type === 'lightMode' ? 'light' : 'dark';
            const themeConfig = this.fetchedConfigurations[type] ?? {};
            if (!_isEmpty(themeConfig)) {
                Object.keys(themeConfig).forEach((key) => {
                    if (themeConfig[key]) {
                        const colorName = this.defaultColorOptions[key];
                        this.updatedConfigurations[type] = {
                            ...this.updatedConfigurations[type],
                            [key]: colorName?.[theme].value ?? '',
                        };
                    }
                });
            }
        });
    };

    @action
    resetToDefaults = () => {
        if (this.realmId) {
            this.setDefaultConfigurationsOfReseller();
        } else {
            this.colorOptions = this.defaultColorOptions;
            this.language = 'en';
            this.handleResetLogo('tppLight');
            this.handleResetLogo('tppDark');
            this.handleResetLogo('footerLogos');
            this.footerType = TRUST_LOGOS;
            this.flowCustomisationList = this.defaultFlowCustomizations;
            this.sessionDetailsSettingsList = this.defaultSessionDetailsSettings;
            this.legalForm = this.defaultLegalForm;
            this.populateLegalTextsLoaders();
            this.tosContent = {};
            this.ppContent = {};
            this.hasAnyLogoChanged = false;
            this.setDefaultConfigurations();
            this.enableDemoId = false;
        }
    };

    @action
    fetchConfigurations = async (memberId: string, subTppId: string) => {
        let url = `/api/webappPortal/v2/configurations/${memberId}`;
        url += subTppId ? `?subTppId=${subTppId}` : '';
        return await axios.get(url);
    };

    @action
    getLogo = async (fileReference: string) => {
        const response = await axios.get(`/api/webappPortal/v2/get-logo/${fileReference}`);
        return response.data.file;
    };

    @action
    uploadLogo = async (
        logo: { [key: string]: string },
        logoName: string,
        memberId: string,
        subTppId: string,
    ) => {
        const response = await axios.post('/api/webappPortal/v2/upload-logo', {
            logo,
            memberId,
            subTppId,
            logoName,
        });
        return response.data.fileReference;
    };

    @action
    fetchFeatureControl = flow(
        function* (
            this: WebAppV2Store,
            memberId: string,
        ): AsyncGeneratorReturnType<ITPPFeatureControl> {
            try {
                const response = yield axios.get(`/api/tppIntegration/feature-control/${memberId}`);
                this.isLegalTextEditable = response.data.consentTC;
            } catch (err) {
                console.error(err);
            }
        }.bind(this),
    );

    @action
    fetchDisplayName = flow(
        function* (this: WebAppV2Store, memberId: string): AsyncGeneratorReturnType {
            try {
                const response = yield axios.get(`/api/member/${memberId}/profile`);
                this.displayName = response.data.profileName;
            } catch (err) {
                console.error(err);
            }
        }.bind(this),
    );

    @action
    fetchAndSaveConfigurations = flow(
        function* (): AsyncGeneratorReturnType {
            const { memberId, refId: subTppId, clientType } = this.rootStore;
            if (clientType !== RESELLER) {
                this.isLegalTextEditable = false;
                this.fetchFeatureControl(memberId);
            }
            this.fetchDisplayName(memberId);
            const res = yield this.fetchConfigurations(memberId, subTppId);
            const {
                tppConfiguration: { configuration, realmId },
            } = res.data;
            this.realmId = realmId;
            this.fetchedConfigurations = configuration;
            for (const key in this.flowCustomisationList) {
                if (typeof this.fetchedConfigurations?.[key]?.value === 'boolean') {
                    this.flowCustomisationList[key] = this.fetchedConfigurations[key]?.value;
                }
            }
            for (const key in this.sessionDetailsSettingsList) {
                if (typeof this.fetchedConfigurations?.[key]?.value === 'boolean') {
                    this.sessionDetailsSettingsList[key] = this.fetchedConfigurations[key]?.value;
                }
            }
            for (const key in this.colorOptions) {
                const lightValue =
                    this.fetchedConfigurations?.lightMode?.[key] ??
                    this.colorOptions[key].light.value;
                const darkValue =
                    this.fetchedConfigurations?.darkMode?.[key] ??
                    this.colorOptions[key].dark.value;
                lightValue !== '' && (this.colorOptions[key].light.value = lightValue);
                darkValue !== '' && (this.colorOptions[key].dark.value = darkValue);
            }
            this.tosContent = this.fetchedConfigurations?.termsAndConditions;
            this.ppContent = this.fetchedConfigurations?.privacyPolicy;
            this.demoId = this.fetchedConfigurations?.demoId;
            this.enableDemoId = !!this.demoId;
            const { lightMode, darkMode, enableTrustIcons } = this.fetchedConfigurations;
            if (lightMode?.footerLogoReference || darkMode?.footerLogoReference) {
                this.footerType = CUSTOM;
            } else if (enableTrustIcons?.value === false) {
                this.footerType = NO_FOOTER_LOGO;
            } else {
                this.footerType = TRUST_LOGOS;
            }
            for (const type of LEGAL_TEXT_TYPES) {
                this.legalForm = {
                    ...this.legalForm,
                    [type]: {
                        ...this.legalForm[type],
                        ...this.fetchedConfigurations?.[type],
                    },
                };
            }
            this.legalForm.displayName = this.displayName;
            this.legalForm.businessName = this.fetchedConfigurations.businessName || 'Token';
            yield this.setLogos();
        }.bind(this),
    );

    @action
    handleLogoUpload = flow(
        function* (
            this: WebAppV2Store,
            event: {
                target: {
                    name:
                        | 'footerLogoLight'
                        | 'footerLogoDark'
                        | 'tppLogoLight'
                        | 'tppLogoDark'
                        | 'faviconLight'
                        | 'faviconDark';
                    files: any[];
                };
            },
        ) {
            const name = event.target.name;
            const file = event.target.files[0];

            try {
                const data = (yield asyncReadFile(file, true)) as string;
                // if the file is favicon, then the size should be less than 100kB, otherwise  1mB;
                const maxSizeAllowed = name.includes('favicon') ? 100 * 1024 : 1024 * 1024;
                // file.size <= maxSizeAllowed and if the file is a favicon, aspect ratio should be 1:1
                const isFileAcceptable =
                    file.size <= maxSizeAllowed &&
                    (!name.includes('favicon') || file.width === file.height);

                if (isFileAcceptable) {
                    const image = new Image();
                    image.src = data;

                    yield asyncImageLoad(image);

                    // if the file is a footer logo, then the height should be 40px, if favicon, 32px, otherwise 20px
                    const targetHeight = name.includes('footer')
                        ? 20
                        : name.includes('favicon')
                        ? 32
                        : 40;
                    const aspectRatio = image.width / image.height;
                    const newWidth = Math.round(targetHeight * aspectRatio);

                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

                    canvas.width = newWidth;
                    canvas.height = targetHeight;
                    ctx.drawImage(image, 0, 0, newWidth, targetHeight);
                    const resizedData = canvas.toDataURL(file.type);

                    action(() => {
                        if (name === 'tppLogoLight') {
                            this.showLightTppLogoError = false;
                            this.tppLogoLight = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                        } else if (name === 'tppLogoDark') {
                            this.showDarkTppLogoError = false;
                            this.tppLogoDark = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                        } else if (name === 'footerLogoLight') {
                            this.showLightFooterLogoError = false;
                            this.footerLogoLight = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                            this.customLogosNotUploaded = false;
                        } else if (name === 'footerLogoDark') {
                            this.showDarkFooterLogoError = false;
                            this.footerLogoDark = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                            this.customLogosNotUploaded = false;
                        } else if (name === 'faviconLight') {
                            this.showLightFaviconError = false;
                            this.faviconLight = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                        } else {
                            this.showDarkFaviconError = false;
                            this.faviconDark = {
                                data: resizedData,
                                mimeType: file.type,
                            };
                        }
                        this.hasAnyLogoChanged = true;
                    })();
                } else {
                    action(() => {
                        switch (name) {
                            case 'tppLogoLight':
                                this.showLightTppLogoError = true;
                                break;
                            case 'tppLogoDark':
                                this.showDarkTppLogoError = true;
                                break;
                            case 'footerLogoLight':
                                this.showLightFooterLogoError = true;
                                break;
                            case 'footerLogoDark':
                                this.showDarkFooterLogoError = true;
                                break;
                            case 'faviconLight':
                                this.showLightFaviconError = true;
                                break;
                            case 'faviconDark':
                                this.showDarkFaviconError = true;
                                break;
                        }
                    })();
                }
            } catch (error) {
                console.error('Error in handleLogoUpload:', error);
                action(() => {
                    switch (name) {
                        case 'tppLogoLight':
                            this.showLightTppLogoError = true;
                            break;
                        case 'tppLogoDark':
                            this.showDarkTppLogoError = true;
                            break;
                        case 'footerLogoLight':
                            this.showLightFooterLogoError = true;
                            break;
                        case 'footerLogoDark':
                            this.showDarkFooterLogoError = true;
                            break;
                        case 'faviconLight':
                            this.showLightFaviconError = true;
                            break;
                        case 'faviconDark':
                            this.showDarkFaviconError = true;
                            break;
                    }
                })();
            }
        }.bind(this),
    );

    @action
    handleLightColorChange = (event: { target: { name: string; value: string } }) => {
        const colorName = event.target.name;
        const value = event.target.value;
        this.colorOptions[colorName].light.value = value;
        if (this.fetchedConfigurations?.lightMode?.[colorName] !== value) {
            if (!this.updatedConfigurations.lightMode) {
                this.updatedConfigurations.lightMode = {};
            }
            this.updatedConfigurations.lightMode[colorName] = value;
        } else {
            delete this.updatedConfigurations?.lightMode?.[colorName];
            if (
                this.updatedConfigurations.lightMode &&
                Object.keys(this.updatedConfigurations.lightMode).length === 0
            ) {
                delete this.updatedConfigurations.lightMode;
            }
        }
    };

    @action
    handleDarkColorChange = (event: { target: { name: string; value: string } }) => {
        const colorName = event.target.name;
        const value = event.target.value;
        this.colorOptions[colorName].dark.value = value;
        if (this.fetchedConfigurations?.darkMode?.[colorName] !== value) {
            if (!this.updatedConfigurations.darkMode) {
                this.updatedConfigurations.darkMode = {};
            }
            this.updatedConfigurations.darkMode[colorName] = value;
        } else {
            delete this.updatedConfigurations?.darkMode?.[colorName];
            if (
                this.updatedConfigurations.darkMode &&
                Object.keys(this.updatedConfigurations.darkMode).length === 0
            ) {
                delete this.updatedConfigurations.darkMode;
            }
        }
    };

    @action
    handleResetLogo = (logoType: String) => {
        switch (logoType) {
            case 'tppLight':
                this.tppLogoLight = this.emptyLogo;
                this.updatedConfigurations.lightMode = {
                    ...this.updatedConfigurations['lightMode'],
                    logoReference: '',
                };
                this.showLightTppLogoError = false;
                break;
            case 'tppDark':
                this.tppLogoDark = this.emptyLogo;
                this.updatedConfigurations.darkMode = {
                    ...this.updatedConfigurations['darkMode'],
                    logoReference: '',
                };
                this.showDarkTppLogoError = false;
                break;
            case 'faviconLight':
                this.faviconLight = this.emptyLogo;
                this.updatedConfigurations.lightMode = {
                    ...this.updatedConfigurations['lightMode'],
                    faviconReference: '',
                };
                this.showLightFaviconError = false;
                break;
            case 'faviconDark':
                this.faviconDark = this.emptyLogo;
                this.updatedConfigurations.darkMode = {
                    ...this.updatedConfigurations['darkMode'],
                    faviconReference: '',
                };
                this.showDarkFaviconError = false;
                break;
            case 'footerLogos':
                this.footerLogoLight = this.footerLogoDark = this.emptyLogo;
                this.showLightFooterLogoError = this.showDarkFooterLogoError = false;
                break;
        }
    };

    @action
    addLogoInConfigurations = (mode: string, type: string, reference: string) => {
        if (reference) {
            this.updatedConfigurations[mode] = {
                ...this.updatedConfigurations[mode],
                [type]: reference,
            };
        }
    };

    @action
    setFileReference = async () => {
        const logos = {
            tppLogoDark: this.tppLogoDark,
            tppLogoLight: this.tppLogoLight,
            footerLogoLight: this.footerLogoLight,
            footerLogoDark: this.footerLogoDark,
            faviconLight: this.faviconLight,
            faviconDark: this.faviconDark,
        };
        const fileReferences: {
            [key: string]: string;
        } = {
            tppLogoLight: '',
            tppLogoDark: '',
            footerLogoLight: '',
            footerLogoDark: '',
            faviconLight: '',
            faviconDark: '',
        };
        const logoPromises = Object.keys(logos).map(async (logo) => {
            fileReferences[logo] = await this.uploadLogo(
                logos[logo as keyof typeof logos],
                logo,
                this.rootStore.memberId,
                this.rootStore.refId,
            );
        });
        await Promise.all(logoPromises);
        for (const mode of ['lightMode', 'darkMode']) {
            this.addLogoInConfigurations(
                mode,
                'logoReference',
                fileReferences[`tppLogo${mode === 'darkMode' ? 'Dark' : 'Light'}`],
            );
            this.addLogoInConfigurations(
                mode,
                'footerLogoReference',
                fileReferences[`footerLogo${mode === 'darkMode' ? 'Dark' : 'Light'}`],
            );
            this.addLogoInConfigurations(
                mode,
                'faviconReference',
                fileReferences[`favicon${mode === 'darkMode' ? 'Dark' : 'Light'}`],
            );
        }
    };

    findElementUntilVisible = async (selector: string, timeout = 1000, interval = 100) => {
        return new Promise((resolve, reject) => {
            const startTime = Date.now();

            const intervalId = setInterval(() => {
                const element = window.document.getElementById(selector);

                if (element) {
                    clearInterval(intervalId);
                    resolve(element);
                }

                if (Date.now() - startTime >= timeout) {
                    clearInterval(intervalId);
                    reject(
                        new Error(
                            `Element with selector '${selector}' not visible after ${timeout}ms`,
                        ),
                    );
                }
            }, interval);
        });
    };

    /* istanbul ignore next */
    initializeModal = () => {
        const appSdk = new window.TokenApp({ env: publicRuntimeConfig.appSdkEnv });
        this.wav2Modal = appSdk.buildController({
            onDone: () => this.setPreviewWAV2(false),
            onError: () => this.setPreviewWAV2(false),
        });
    };

    @action
    setPreviewWAV2(value: boolean) {
        this.previewWAV2 = value;
    }

    @action
    handleResizeModal = () => {
        const windowHeight = window.innerHeight;
        const messenger = new window.TokenMessenger({
            source: window,
            destination: window,
        });

        if (windowHeight < 700) {
            messenger?.dispatchMessage({
                type: 'SET_MODAL_STYLE',
                data: { height: '500px', width: '420px' },
            });
            this.modalTabView = 1;
        } else if (windowHeight < 800) {
            messenger?.dispatchMessage({
                type: 'SET_MODAL_STYLE',
                data: { height: '600px', width: '420px' },
            });
            this.modalTabView = 1;
        } else {
            messenger?.dispatchMessage({
                type: 'SET_MODAL_STYLE',
                data: { height: '650px', width: '700px' },
            });
            this.modalTabView = 0;
        }
    };

    @action
    handlePreview = flow(
        function* (): any {
            try {
                if (
                    this.footerType === CUSTOM &&
                    (!this.footerLogoLight.data || !this.footerLogoDark.data)
                ) {
                    this.customLogosNotUploaded = true;
                } else {
                    this.setPreviewWAV2(true);
                    const webAppIframeEl = yield this.findElementUntilVisible('tokenWebAppIframe');
                    this.wav2Modal.initAppIframe({ parentEl: webAppIframeEl });
                    window.removeEventListener('resize', this.handleResizeModal);
                    window.addEventListener('resize', this.handleResizeModal);
                    this.handleResizeModal();
                    this.customLogosNotUploaded = false;
                    this.disablePreviewButton = true;

                    yield this.setFileReference();

                    const config: { key: { value: boolean } } = _merge(
                        { ...this.fetchedConfigurations },
                        { ...this.updatedConfigurations },
                    );

                    this.previewConfiguration = Object.fromEntries(
                        Object.entries(config)
                            .filter(
                                ([_, value]) =>
                                    value !== null &&
                                    !(typeof value === 'object' && Object.keys(value).length === 0),
                            )
                            .map(([key, value]) => [
                                key,
                                value && value.value !== undefined ? value.value : value,
                            ]),
                    );

                    const configBase64 = Buffer.from(
                        JSON.stringify({ configuration: this.previewConfiguration }),
                    ).toString('base64');
                    const sessionId = getUniqueStr(9);
                    const wav2Url =
                        `${publicRuntimeConfig.paymentApp}/session/${sessionId}` +
                        '?previewMode=true' +
                        `&tppConfig=${encodeURIComponent(configBase64)}` +
                        `&memberId=${encodeURIComponent(this.rootStore.memberId)}` +
                        `&subTppId=${encodeURIComponent(this.rootStore.refId)}`;
                    this.wav2Modal.navigateToUrl(wav2Url);
                }
            } catch (e) {
                console.error(e);
                this.wav2Modal.cleanUp();
                this.GenericErrorStore.openErrorModal(e); // Handle any errors
                this.setPreviewWAV2(false);
            } finally {
                this.disablePreviewButton = false;
            }
        }.bind(this),
    );

    @action
    handleSave = flow(
        function* (): any {
            try {
                if (
                    this.footerType === CUSTOM &&
                    (!this.footerLogoLight.data || !this.footerLogoDark.data)
                ) {
                    this.customLogosNotUploaded = true;
                } else {
                    this.customLogosNotUploaded = false;
                    this.loadingSaveButton = true;
                    this.disableSaveButton = true;
                    yield this.setFileReference();
                    const finalConfiguration = _merge(
                        { ...this.fetchedConfigurations },
                        { ...this.updatedConfigurations },
                    );

                    if (this.footerType !== CUSTOM) {
                        finalConfiguration.lightMode &&
                            (finalConfiguration.lightMode.footerLogoReference = '');
                        finalConfiguration.darkMode &&
                            (finalConfiguration.darkMode.footerLogoReference = '');
                    }
                    const res = yield axios.post('/api/webappPortal/v2/configurations', {
                        memberId: this.rootStore.memberId,
                        subTppId: this.rootStore.refId,
                        configuration: finalConfiguration,
                    });
                    const {
                        tppConfiguration: { configuration },
                    } = res.data;
                    this.fetchedConfigurations = configuration;
                    this.updatedConfigurations = {};
                    this.hasAnyLogoChanged = false;
                    this.GenericSuccessStore.openModal();
                }
            } catch (e) {
                console.error(e);
                this.GenericErrorStore.openErrorModal(e);
            } finally {
                this.loadingSaveButton = false;
                this.disableSaveButton = false;
            }
        }.bind(this),
    );

    @action
    handleTranslationChange = (name: string, value: string) => {
        this.legalForm = {
            ...this.legalForm,
            [name]: {
                ...this.legalForm[name as LegalTextTypes],
                [this.language]: value,
            },
        };

        if (
            this.fetchedConfigurations?.[name]?.[this.language] !==
            this.legalForm[name as LegalTextTypes]?.[this.language]
        ) {
            this.updatedConfigurations = {
                ...this.updatedConfigurations,
                [name]: {
                    ...this.updatedConfigurations[name],
                    [this.language]: value,
                },
            };
        } else {
            if (this.updatedConfigurations?.[name]?.[this.language]) {
                delete this.updatedConfigurations[name][this.language];
                if (Object.keys(this.updatedConfigurations[name]).length === 0) {
                    delete this.updatedConfigurations[name];
                }
            }
        }
    };

    @action
    getEditContentPlainText = (contentType: string) => {
        if (contentType === 'editTOS') {
            return this.tosContent[this.language] || '';
        } else {
            return this.ppContent[this.language] || '';
        }
    };

    @action
    handleEditContent = (name: string, value: string) => {
        if (name === 'editTOS') {
            this.tosContent = { ...this.tosContent, [this.language]: value };
            if (this.fetchedConfigurations?.termsAndConditions?.[this.language] !== value) {
                if (!this.updatedConfigurations.termsAndConditions) {
                    this.updatedConfigurations.termsAndConditions = {};
                }
                this.updatedConfigurations.termsAndConditions[this.language] = value;
            } else {
                delete this.updatedConfigurations?.termsAndConditions?.[this.language];
                if (
                    this.updatedConfigurations.termsAndConditions &&
                    Object.keys(this.updatedConfigurations.termsAndConditions).length === 0
                ) {
                    delete this.updatedConfigurations.termsAndConditions;
                }
            }
        } else if (name === 'editPP') {
            this.ppContent = { ...this.ppContent, [this.language]: value };
            if (this.fetchedConfigurations?.privacyPolicy?.[this.language] !== value) {
                if (!this.updatedConfigurations.privacyPolicy) {
                    this.updatedConfigurations.privacyPolicy = {};
                }
                this.updatedConfigurations.privacyPolicy[this.language] = value;
            } else {
                delete this.updatedConfigurations?.privacyPolicy?.[this.language];
                if (
                    this.updatedConfigurations.privacyPolicy &&
                    Object.keys(this.updatedConfigurations.privacyPolicy).length === 0
                ) {
                    delete this.updatedConfigurations.privacyPolicy;
                }
            }
        }
    };

    @action
    handleEditSessionDetailsSettings = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        this.sessionDetailsSettingsList[name] = checked;
        if (this.fetchedConfigurations?.[name]?.value !== checked) {
            this.updatedConfigurations[name] = {
                value: checked,
            };
        } else {
            delete this.updatedConfigurations[name];
        }
    };

    @action
    handleChangeFlowCustomisation = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        this.flowCustomisationList[name] = checked;
        if (this.fetchedConfigurations?.[name]?.value !== checked) {
            if (name === 'enableTransactionStatusPage' && checked) {
                this.handleEditSessionDetailsSettings({
                    target: {
                        name: 'showDebtorAccountIdentifier',
                        checked: true,
                    },
                } as React.ChangeEvent<HTMLInputElement>);
                this.handleEditSessionDetailsSettings({
                    target: {
                        name: 'showDebtorBankIdentifier',
                        checked: true,
                    },
                } as React.ChangeEvent<HTMLInputElement>);
            }
            this.updatedConfigurations[name] = {
                value: checked,
            };
        } else {
            delete this.updatedConfigurations[name];
        }
    };
}

export default WebAppV2Store;
