/**
 * Generates a unique value with a customizable length
 *
 * @param {number} length - The desired length of the unique value (default is 9)
 * @returns {string} - A unique alphanumeric string of the specified length
 */
export default function getUniqueStr(length = 9) {
    return Math.random()
        .toString(35)
        .substring(2, 2 + length);
}
